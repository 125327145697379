import React from 'react';
import styled from 'styled-components';
import StyledText from 'typography/StyledText';

import { getCurrencySymbol } from '../../state/atoms/pricing';

const SecurityDepositContainer = styled.div`
	margin: 16px 0 0 0;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface ISecurityDeposit {
	currency: string;
	securityDeposit: number;
	typographyTextType?:
		| 'body'
		| 'caption'
		| 'label'
		| 'lead'
		| 'body_longform'
		| 'footnote';
}

const SecurityDeposit: React.FC<ISecurityDeposit> = ({
	currency,
	securityDeposit,
	typographyTextType = 'label',
}) => {
	if (!securityDeposit) return null;
	const symbol = getCurrencySymbol(currency);
	return (
		<SecurityDepositContainer>
			<StyledText
				type={typographyTextType}
			>{`This property requires a ${symbol}${securityDeposit} refundable security deposit, which will be collected separately by the host either before your arrival or at check-in.`}</StyledText>
		</SecurityDepositContainer>
	);
};

export default SecurityDeposit;
