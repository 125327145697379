import {
	CancellationPreferenceTypes,
	cancellationPolicyStrings,
} from 'lib/cancellationPolicy';
import React from 'react';
import styled from 'styled-components';
import StyledText from 'typography/StyledText';

const infomationIcon = '/icons/information.svg';

const CancellationContainer = styled.div`
	margin: 16px 0 0 0;
	text-align: left;
	display: flex;
	align-items: initial;
	justify-content: left;
`;

const HighlightedText = styled(StyledText)`
	color: ${(props) => props.theme?.colors.BB100};
	cursor: pointer;
`;

const Icon = styled.img`
	width: 24px;
	height: 24px;
	cursor: pointer;
`;

interface ICancellationPolicy {
	cancellation_policy: CancellationPreferenceTypes;
	check_in: string;
}

const CancellationPolicy: React.FC<ICancellationPolicy> = ({
	cancellation_policy,
	check_in,
}) => {
	const [policyText, dateHighlightText] = cancellationPolicyStrings(
		cancellation_policy,
		check_in,
	);

	return (
		<CancellationContainer>
			<StyledText type="label">
				<>{policyText}</>
			</StyledText>
			&nbsp;
			<HighlightedText type="label">{dateHighlightText}</HighlightedText>
			<Icon src={infomationIcon} />
		</CancellationContainer>
	);
};

export default CancellationPolicy;
