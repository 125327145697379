import { Temporal } from '@js-temporal/polyfill';
import CancellationPolicyCard from 'components/CancellationPolicy';
import StayPriceBreakdown from 'components/StayPriceBreakdown';
import StyledButton from 'components/StyledButton';
import StyledInput from 'components/StyledInput';
import React from 'react';
import { IStayAtom, hasSelectedStay } from 'state/atoms/stay';
import styled from 'styled-components';
import typographyGenerator from 'typography/generator';

import SecurityDeposit from '../SecurityDeposit';

const Container = styled.div`
	width: 100%;
`;

const Label = styled.div`
	${(props) => typographyGenerator('label', props.theme)}
	color: ${(props) => props.theme?.colors.LM_N60};
`;

const CheckinCheckoutContainer = styled.div`
	display: flex;
	gap: 10px;
	width: 100%;
`;

const ButtonContainer = styled.div`
	display: flex;
	padding: 10px 0 0 0;
	justify-content: center;
	align-items: center;
	gap: 24px;
	border-radius: 8px;
	align-self: stretch;
	flex-grow: 0;
`;

const DateInput = styled(StyledInput)`
	min-width: 100px;
	margin-bottom: 5px;
`;

const GuestInput = styled(StyledInput)``;

const ActionButton = styled(StyledButton)`
	min-width: 100%;
	:disabled {
		background-color: #ec8381;
		cursor: default;
	}
`;

const CancellationContainer = styled.div`
	margin: 16px 0px 0px 0px;
	text-align: center;
`;

const DateSpacer = styled.div`
	width: 0px;
	display: none;
	@media screen and (max-width: 375px) {
		display: block;
		width: 8px;
	}
`;
interface IAvailabilityFromInput {
	availabilityTitle: string;
	reservationTitle: string;
	cancellationPolicy: string;
	handleAvailabilityClick: () => void;
	handleReservationClick: () => void;
	handleGuestClick: () => void;
	handleCancellationPolicyClick: () => void;
	stay: IStayAtom;
	discountInvalid: boolean;
	securityDeposit: number;
}

const AvailabilityFromInput: React.FC<IAvailabilityFromInput> = ({
	availabilityTitle,
	reservationTitle,
	cancellationPolicy,
	handleAvailabilityClick,
	handleReservationClick,
	handleGuestClick,
	handleCancellationPolicyClick,
	stay,
	discountInvalid,
	securityDeposit = 0,
}) => {
	let buttonText = availabilityTitle;
	if (hasSelectedStay(stay)) {
		buttonText = reservationTitle;
	}
	return (
		<Container>
			<Label>Dates</Label>
			<CheckinCheckoutContainer>
				<DateInput
					placeholder="Add check-in"
					value={
						stay.startDate
							? Temporal.PlainDate.from(
									stay.startDate!,
								).toLocaleString()
							: ''
					}
					handleClick={handleAvailabilityClick}
					readOnly
				/>
				<DateSpacer />
				<DateInput
					placeholder="Add check-out"
					value={
						stay.endDate
							? Temporal.PlainDate.from(
									stay.endDate!,
								).toLocaleString()
							: ''
					}
					handleClick={handleAvailabilityClick}
					readOnly
				/>
			</CheckinCheckoutContainer>
			<Label>Guests</Label>
			<GuestInput
				showOptionArrow
				value={stay.adults + stay.children}
				handleClick={handleGuestClick}
				type="button"
			/>

			<StayPriceBreakdown
				title=""
				checkInDate={stay.startDate ?? ''}
				checkOutDate={stay.endDate ?? ''}
				stayPriceBreakdown={{
					isDateSelected: stay.isDateSelected ?? false,
					hasAvailability: stay.hasAvailability ?? false,
					currency: stay.currency ?? '',
					baseStayPrice: stay.baseStayPrice ?? 0,
					totalBeforeDiscount: stay.totalBeforeDiscount ?? 0,
					totalToPayToday: stay.totalToPayToday ?? 0,
					minStay: stay.minStay ?? 0,
					lineItems: stay.lineItems ?? [],
					error: stay.error,
				}}
				discountInvalid={discountInvalid}
			/>

			<ButtonContainer>
				<ActionButton
					title={buttonText}
					disabled={discountInvalid}
					handleClick={() => {
						if (hasSelectedStay(stay)) {
							handleReservationClick();
						} else {
							handleAvailabilityClick();
						}
					}}
				/>
			</ButtonContainer>
			{hasSelectedStay(stay) ? (
				<>
					<CancellationContainer
						onClick={handleCancellationPolicyClick}
					>
						<CancellationPolicyCard
							cancellation_policy={cancellationPolicy as any}
							check_in={stay.startDate!}
						/>
					</CancellationContainer>
					<SecurityDeposit
						currency={stay.currency ?? ''}
						securityDeposit={securityDeposit}
					/>
				</>
			) : null}
		</Container>
	);
};

export default AvailabilityFromInput;
